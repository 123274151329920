import React from "react";
import maize from "./../img/maize-gluten-feed.JPG";

function Maizeglutenfeed() {
   return (
      <div class="mt-32 mb-16 flex justify-center">
         <img src={maize} alt="" srcset="" />
      </div>
   );
}

export default Maizeglutenfeed;
