import React from "react";
import cow from "./cow-no-bg.png";
import { motion } from "framer-motion";

function Main() {
   return (
      <div class="min-h-screen flex flex-col md:flex-row">
         <motion.div
            initial={{ x: -1000 }}
            animate={{ x: 0 }}
            transition={{ delay: 0.5, duration: 2 }}
            class="md:w-2/3 md:h-screen"
         >
            <img
               class="h-full object-cover"
               src="https://images.pexels.com/photos/195226/pexels-photo-195226.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
               alt="main-section"
            />
         </motion.div>

         <motion.div
            initial={{ x: 1000 }}
            animate={{ x: 0 }}
            transition={{ delay: 0.5, duration: 2 }}
            class="my-auto md:w-1/3"
         >
            <div class="px-8 py-8 md:pt-32">
               <div
                  class="font-bold text-6xl"
                  style={{ fontFamily: "'Barlow Semi Condensed', sans-serif" }}
               >
                  Food that cares for your animals.
               </div>
               <hr
                  class="mt-4"
                  style={{
                     width: "50%",
                     borderColor: "red",
                     textAlign: "left",
                     border: "solid 2px #ff554f",
                  }}
               />
               <div class="font-semibold pt-8 text-gray-600">
                  {/* Lorem, ipsum dolor sit amet consectetur adipisicing elit. Esse
                  reprehenderit velit molestiae illum labore rem asperiores
                  debitis nemo, veniam libero. */}
                  Your animals' diet can have a drastic effect on their
                  well-being. We know you want the best for them, so do we.
               </div>
               <motion.img
                  onContextMenu={(e) => e.preventDefault()}
                  src={cow}
                  class="lg:mt-16"
                  whileHover={{
                     x: 12,
                     transition: { duration: 1 },
                  }}
                  whileTap={{
                     x: 12,
                     transition: { duration: 0.2 },
                  }}
                  style={{
                     width: "128px",
                     position: "absolute",
                     right: "-52px",
                     overflow: "hidden",
                  }}
               />
            </div>
         </motion.div>
      </div>
   );
}

export default Main;
