import React from "react";
import mainLogo from "./../logo.png";
import { useNavigate } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

function Navbar() {
   let navigate = useNavigate();
   return (
      <nav
         class="
            absolute
            w-full
            flex flex-wrap
            items-center
            justify-between
            
            bg-transparent
            text-gray-500
            hover:text-gray-700
            focus:text-gray-700
            shadow-lg
            navbar navbar-expand-lg navbar-light
            "
      >
         <div class="container-fluid w-full flex flex-wrap items-center justify-between">
            <a
               class="
                    flex
                    items-center
                    text-gray-900
                    hover:text-gray-900
                    focus:text-gray-900
                    mt-2
                    lg:mt-0
                    mr-1
                "
               onClick={() => {
                  navigate("/");
               }}
            >
               <img
                  src={mainLogo}
                  class="h-16 md:pl-8 md:h-20 pl-6"
                  alt=""
                  loading="lazy"
               />
            </a>

            <button
               class="
                navbar-toggler
                text-gray-500
                pr-6
                border-0
                hover:shadow-none hover:no-underline 
                ml-auto
                py-2
                px-2.5
                bg-transparent
                focus:outline-none focus:ring-0 focus:shadow-none focus:no-underline
                "
               type="button"
               data-bs-toggle="collapse"
               data-bs-target="#navbarSupportedContent"
               aria-controls="navbarSupportedContent"
               aria-expanded="false"
               aria-label="Toggle navigation"
            >
               <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="bars"
                  class="w-6"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
               >
                  <path
                     fill="currentColor"
                     d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                  ></path>
               </svg>
            </button>
            <div
               class="collapse navbar-collapse flex-grow items-center"
               id="navbarSupportedContent"
            >
               {/* <!-- Left links --> */}
               <ul class="navbar-nav flex flex-col ml-auto list-style-none bg-white px-6">
                  <li class="nav-item p-2">
                     <Link
                        to="/#products"
                        class="nav-link text-gray-500 hover:text-gray-700 focus:text-gray-700 p-0"
                        href="#"
                     >
                        Products
                     </Link>
                  </li>
                  <li class="nav-item p-2">
                     <a
                        onClick={() => {
                           navigate("/about");
                        }}
                        class="nav-link text-gray-500 hover:text-gray-700 focus:text-gray-700 p-0"
                        href="#"
                     >
                        About
                     </a>
                  </li>
                  <li class="nav-item p-2">
                     <Link
                        to="#footer"
                        class="nav-link text-gray-500 hover:text-gray-700 focus:text-gray-700 p-0"
                        href="#"
                     >
                        Contact
                     </Link>
                  </li>
               </ul>
               {/* <!-- Left links --> */}
            </div>
         </div>
      </nav>
   );
}

export default Navbar;
