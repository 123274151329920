import React from "react";
import rapeseed from "./../img/rapeseed-doc.JPG";

function Rapeseeddoc() {
   return (
      <div class="mt-32 mb-16 flex justify-center">
         <img src={rapeseed} alt="" srcset="" />
      </div>
   );
}

export default Rapeseeddoc;
