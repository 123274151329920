import './App.css';
import ScrollToTop from './components/ScrollToTop';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Homepage from './pages/Homepage';
import Error404 from './pages/Error404';
import About from './pages/About';
import Groundnut from './pages/GroundnutDoc';
import Rapeseed from './pages/Rapeseeddoc';
import Maizeglutenfeed from './pages/Maizeglutenfeed';
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';

function App() {
  return (
    <div 
      style={{overflow:"hidden", position:"relative"}}
    >
      
      <Router primary={false}>
      <ScrollToTop/>
      <Navbar/>
        <Routes>
          <Route path="/" element={<Homepage/>}/>
          <Route path="*" element={<Error404/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/groundnutdoc" element={<Groundnut/>}/>
          <Route path="/rapeseeddoc" element={<Rapeseed/>}/>
          <Route path="/maizeglutenfeed" element={<Maizeglutenfeed/>}/>
        </Routes>
        <Footer/>
      </Router>
    
    </div>
  );
}

export default App;
