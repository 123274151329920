import React from "react";
import groundnut from "./../img/groundnut-doc.JPG";

function GroundnutDoc() {
   return (
      <div class="mt-32 mb-16 flex justify-center">
         <img src={groundnut} alt="" srcset="" />
      </div>
   );
}

export default GroundnutDoc;
