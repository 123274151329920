import React from "react";
import Main from "./../components/Main";
import Products from "./../components/Products";
import Message from "./../components/Message";
import Modal from "./../components/Modal";

function Homepage() {
   return (
      <div>
         <Main />
         {/* <Modal /> */}
         <Products />
         <Message />
      </div>
   );
}

export default Homepage;
