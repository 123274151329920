import React from "react";
import logo from "./../img/sehatlogo.png";

function About() {
   return (
      <div class="my-24 flex flex-col items-center">
         <img src={logo} alt="main-logo" />
         <p class="text-xl mx-16">
            SEHAT BIOFOODS is a leading manufacturer and supplier of Protein
            sources and supplements for animal feeds. We have grown into India’s
            most trusted company for feed ingredients like Maize Gluten Meal,
            Musterd DOC , Ground Nut DOC, Soya DOC, Guar Gum Meal, Castor DOC,
            across Poultry, Cattle, Aquaculture and other livestock sectors.
         </p>
         <br />
         <p class="text-xl mx-16">
            We are committed to supplying our customer’s best quality material
            at economical rates and have gained trust of feed manufacturers and
            farmers across India.We strive to build long-term relationships
            through outstanding service, while leveraging our considerable
            experience in the markets we serve.
         </p>
      </div>
   );
}

export default About;
