import React from "react";
import { motion } from "framer-motion";
import hen from "./../hen-no-bg.png";
import maize from "./../img/maize-gluten-feed.JPG";
import groundnut from "./../img/groundnut-doc.JPG";
import rapeseed from "./../img/rapeseed-doc.JPG";
import { useNavigate } from "react-router-dom";

function Products() {
   let navigate = useNavigate();
   return (
      <div
         id="products"
         class="md:h-screen mx-8 mt-16 flex flex-col justify-center"
      >
         <section class="overflow-hidden text-gray-700">
            <h2 class="text-red-500 font-bold text-3xl text-center font-serif">
               Animal Products
            </h2>
            <h3 class="text-xl text-center py-2">
               For your animal's good health
            </h3>

            <div class="px-5 py-2 mx-auto lg:pt-12 lg:px-32">
               <div class="flex flex-wrap">
                  <motion.div
                     class="flex flex-wrap w-full md:w-1/3 cursor-pointer"
                     onClick={() => {
                        navigate("/groundnutdoc");
                     }}
                     whileHover={{
                        scale: 0.8,
                     }}
                     whileTap={{
                        scale: 0.8,
                     }}
                     transition={{
                        duration: 0.5,
                     }}
                  >
                     <div class="w-full">
                        <img
                           alt="gallery"
                           class="block object-cover object-center w-full h-full"
                           src="https://i.imgur.com/R4pLgAk.jpg"
                           // src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(70).webp"
                        />
                     </div>
                  </motion.div>

                  <motion.div
                     class="flex flex-wrap w-full md:w-1/3 cursor-pointer"
                     onClick={() => {
                        navigate("/rapeseeddoc");
                     }}
                     whileHover={{
                        scale: 0.8,
                     }}
                     whileTap={{
                        scale: 0.8,
                     }}
                     transition={{
                        duration: 0.5,
                     }}
                  >
                     <div class="w-full">
                        <img
                           alt="gallery"
                           class="block object-cover object-center w-full h-full"
                           src="https://i.imgur.com/aXPrj9h.jpg"
                           // src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(74).webp"
                        />
                     </div>
                  </motion.div>
                  <motion.div
                     class="flex flex-wrap w-full md:w-1/3 cursor-pointer"
                     onClick={() => {
                        navigate("/maizeglutenfeed");
                     }}
                     whileHover={{
                        scale: 0.8,
                     }}
                     whileTap={{
                        scale: 0.8,
                     }}
                     transition={{
                        duration: 0.5,
                     }}
                  >
                     <div class="w-full">
                        <img
                           alt="gallery"
                           class="block object-cover object-center w-full h-full"
                           src="https://i.imgur.com/Ju1S8s8.jpg"
                           // src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(75).webp"
                        />
                     </div>
                  </motion.div>
                  {/* <div class="flex flex-wrap w-full md:w-1/3">
                     <div class="w-full">
                        <img
                           alt="gallery"
                           class="block object-cover object-center w-full h-full"
                           src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(70).webp"
                        />
                     </div>
                  </div> */}
                  {/* <div class="flex flex-wrap w-full md:w-1/3">
                     <div class="w-full">
                        <img
                           alt="gallery"
                           class="block object-cover object-center w-full h-full"
                           src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(76).webp"
                        />
                     </div>
                  </div> */}
                  {/* <div class="flex flex-wrap w-full md:w-1/3">
                     <div class="w-full">
                        <img
                           alt="gallery"
                           class="block object-cover object-center w-full h-full"
                           src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(72).webp"
                        />
                     </div>
                  </div> */}
               </div>
            </div>
         </section>
         <motion.img
            onContextMenu={(e) => e.preventDefault()}
            src={hen}
            // class="lg:mt-64"
            whileHover={{
               x: -12,
               transition: { duration: 1 },
            }}
            whileTap={{
               x: -12,
               transition: { duration: 0.2 },
            }}
            style={{
               width: "128px",
               position: "relative",
               left: "-92px",
               overflow: "hidden",
            }}
         />
      </div>
   );
}

export default Products;
