import React from "react";
import logo from "./../img/sehatlogo.png";

function Error404() {
   return (
      <div class="my-24 text-3xl flex flex-col items-center">
         <img src={logo} alt="main-logo" />
         <p>404 Page not found</p>
         <p>
            Go to{" "}
            <a class="underline decoration-blue-600" href="/">
               Home
            </a>
         </p>
      </div>
   );
}

export default Error404;
