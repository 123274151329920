import React from "react";
import { motion } from "framer-motion";

function Message() {
   return (
      <div class="md:flex h-full my-12 px-16 md:px-24">
         <motion.div class="md:w-1/2 md:pr-2 mt-12 md:mt-0">
            <div>
               <img
                  class="rounded-full w-24"
                  style={{
                     float: "right",
                     shapeOutside: "circle(60%)",
                  }}
                  src="https://berrychemvetcare.com/img/chirag_tiwari.jpg"
                  alt="chirag tiwari"
               />
               <p class="font-mono font-medium">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis
                  ullam voluptas totam nihil ipsum quae optio distinctio error
                  earum iste? Lorem ipsum dolor sit amet, consectetur
                  adipisicing elit. Quam consectetur similique dicta, vero sed
                  non nam. Beatae pariatur animi ex voluptas! Dolor voluptatem
                  consequuntur suscipit id totam placeat veniam tempora. Ipsum
                  voluptas molestias itaque perspiciatis consequuntur cum magni,
                  id ad deleniti quam neque repellendus, rerum architecto sit
                  tempora consequatur temporibus.
               </p>

               <div class="pt-4 text-semibold font-semibold font-serif">
                  Chirag Tiwari
               </div>
               <div class="text-red-500">Assistant Director</div>
            </div>
         </motion.div>

         <motion.div class="md:w-1/2 text-right mt-24 md:mt-0 md:pl-2">
            <div>
               <img
                  class="rounded-full w-24"
                  style={{
                     float: "left",
                     shapeOutside: "circle(60%)",
                  }}
                  src="https://berrychemvetcare.com/img/director.jpg"
                  alt="Sandeep Tiwari"
               />
               <p class="font-mono font-medium">
                  Sandeep Kumar Tiwari is founding director at{" "}
                  <strong>Sehat Biofoods</strong> and handles marketing,
                  operations and quality functions. He has been instrumental in
                  the company’s growth strategy and development in the Indian
                  Market. He is a first generation entrepreneur and businessman.
                  <br />
                  Previously, he has worked as a Business Development Manager
                  for South East Asia in USA based company, where he specialized
                  in the field of Supply Chain and Operations Management. He has
                  also worked with Swadeshi as Production Manager. He has
                  completed his MBA from Chennai.
                  <br />
                  {/* At <strong>Sehat Biofoods</strong>, the whole team’s constant
                  effort is to meet the requirements of our clients in the
                  livestock industry (Poultry, Cattle, Swine, Aquaculture etc.)
                  and provide high quality feed ingredients at economical rates. */}
               </p>

               <div class="pt-4 text-semibold font-semibold font-serif">
                  Sandeep Kumar Tiwari
               </div>
               <div class="text-red-500">Director</div>
            </div>
         </motion.div>
      </div>
   );
}

export default Message;
